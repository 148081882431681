<template>
  <BModal
    id="modal-delete-folder"
    ref="modal-delete-folder"
    centered
    size="md"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div class=" my-2">
      <div class="text-center">
        <div class="flex items-center justify-center mt-3">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            width="100px"
            alt="logo warning"
          >
        </div>
        <div class="text-[#333] my-2">
          <b class="text-2xl">Hapus Folder?</b>
        </div>
        <p class="text-[#333] my-2">
          Kamu akan menghapus folder ini, semua data akan terhapus!, apakah kamu yakin untuk melanjutkan ?
        </p>
      </div>
      <BRow class="justify-center flex-nowrap">
        <BCol lg="4">
          <b-button
            variant="outline-primary"
            block
            class="mb-1"
            @click="cancelDelete"
          >
            Batal
          </b-button>
        </BCol>
        <BCol lg="4">
          <b-button
            variant="primary"
            block
            class="mb-1"
            @click="confirmDelete"
          >
            Iya
          </b-button>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalDeleteFolder',
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    getAllKontak: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    cancelDelete() {
      this.$bvModal.hide('modal-delete-folder')
    },
    async confirmDelete() {
      this.$bvModal.hide('modal-delete-folder')
      await this.$http_new.delete(`/komchat-api/api/v1/group-contact/delete/${this.id}`)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil dihapus!',
                variant: 'success',
              },
            },
            2000,
          )
          this.getAllKontak()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal dihapus!',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
  },
}
</script>
