import { render, staticRenderFns } from "./GrupKontak.vue?vue&type=template&id=33e1c274&scoped=true&"
import script from "./GrupKontak.vue?vue&type=script&lang=js&"
export * from "./GrupKontak.vue?vue&type=script&lang=js&"
import style0 from "./GrupKontak.vue?vue&type=style&index=0&id=33e1c274&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e1c274",
  null
  
)

export default component.exports